import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import styles from './blog-post.module.css'
import SEO from '../components/Seo'
import Image from '../components/Image'

export default function BlogPage({ data }) {
  const post = data.allWpPage.nodes[0]
  const fluid = post?.featuredImage?.node?.localFile?.childImageSharp?.fluid
  const { content, title, seo } = post
  const { metaRobotsNofollow, metaRobotsNoindex } = seo
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        canonical={seo.canonical}
        schema={seo.schema.raw}
        meta={[
          {
            name: 'robots',
            content: `${metaRobotsNoindex}, ${metaRobotsNofollow}`,
          },
        ]}
      />
      <div>
        <h1>{title}</h1>
        {fluid && <Image className={styles.featuredimage} fluid={fluid} />}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    wp {
      seo {
        social {
          twitter {
            username
          }
        }
      }
    }
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        date(fromNow: true)
        dateGmt
        seo {
          canonical
          metaDesc
          focuskw
          metaKeywords
          title
          metaRobotsNofollow
          metaRobotsNoindex
          schema {
            raw
          }
        }
        featuredImage {
          node {
            seo {
              metaDesc
            }
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
